export const comercialZap = "https://wa.me/553171714838"
export const financeiroZap = "https://wa.me/553172089557"
export const atendimentoZap = "https://wa.me/553171716237"
export const administrativoZap = "https://wa.me/553195704986"

export const comercialEmail = "mailto:comercial@cedoc.net.br"
export const financeiroEmail = "mailto:financeiro@cedoc.net.br"
export const atendimentoEmail = "mailto:atendimento@cedoc.net.br"
export const administrativoEmail = "mailto:administrativo@cedoc.net.br"

export const geralTel = "tel:5531936562040"
export const geralTel2 = "tel:5531999900722"
export const cedocLocationAdm = "https://maps.app.goo.gl/F2m81kVqD6RnrCzYA"
export const cedocLocationArm = "https://maps.app.goo.gl/5zDct9Vp1EKDkVPZA"

export const linkFormFile = "https://fd.cedoc.net.br/FileDirector/basicaccess?action=getform&id1=qeJv5z5QnWt8aXVRmEN%2fJQ%3d%3d&id2=O%2fvC1DDXwGKM49gfCLzuzH%2fpzrRUkTEvgZ23%2btPA3gM%3d"

export const acosAliancaSite = "http://www.acosalianca.com.br/"
export const beloturSite = "https://prefeitura.pbh.gov.br/belotur"
export const bmgSite = "https://www.bancobmg.com.br/"
export const cohabSite = "http://www.cohab.mg.gov.br/"
export const craSite = "https://www.cramg.org.br/"
export const detraSite = "https://transito.mg.gov.br/"
export const fiocruzSite = "https://portal.fiocruz.br/"
export const rhMagnesiteSite = "https://www.rhimagnesita.com/pt/"
export const odilonSite = "https://prefeitura.pbh.gov.br/index.php/hospital-metropolitano-odilon-behrens"
export const paulwurthSite = "https://www.paulwurth.com/"
export const spdaSite = "https://www.prefeitura.sp.gov.br/cidade/secretarias/fazenda/spda/"
export const unimedSite = "https://www.unimed.coop.br/site/"